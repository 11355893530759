import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
// import SecureLS from 'secure-ls';
// var ls = new SecureLS({ encodingType: 'aes' });

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [createPersistedState({ storage: window.sessionStorage, key: '4GJGH', })
		// createPersistedState({
		// 	storage: {
		// 		getItem: (key) => ls.get(key),
		// 		setItem: (key, value) => ls.set(key, value),
		// 		removeItem: (key) => ls.remove(key)
		// 	}
		// })
	],
	state: {
		pageTitle: '',
		pageTitleIcon: '',
		//event_name:'',
		//venue_name:'',
		deadline_set: '',
		token: '',
		eventInfo: {
			EventId: "",
			VenueId: "",
			EventName: "",
			VenueName: "",
			Deadline:"",
			CompanyNameSize: "",
			UserName: "",
			UType: "",
			EventUrl: "",
			venuetype: "",
			templateId: "",
			media_template_id:"",
			approvallevel: "",
			EventAdminID: "",
			Venues: [],
		},


	},
	mutations: {
		setPageTitle(state, payload) {
			state.pageTitle = payload;
		},

		setPageTitleIcon(state, payload) {
			state.pageTitleIcon = payload;
		},
		setEventInfo(state, payload) {
			state.eventInfo.EventId = payload.event_id;
			state.eventInfo.VenueId = payload.venue_id;
			state.eventInfo.EventName = payload.event_name;
			state.eventInfo.VenueName = payload.venue_name;
			state.eventInfo.Deadline =  payload.deadline;
			state.eventInfo.CompanyNameSize = payload.company_name_size;
			state.eventInfo.UType = payload.u_type;
			state.eventInfo.UserName = payload.admin_name;
			state.eventInfo.venuetype = payload.venue_type;
			state.eventInfo.templateId = payload.template_id;
			state.eventInfo.media_template_id = payload.media_template_id;
			
			state.eventInfo.approvallevel = payload.approval_level;

			state.eventInfo.EventAdminID = payload.eaid;
			state.eventInfo.Venues = payload.venues;
			state.token = payload.token;
			sessionStorage.setItem("token", payload.token);
			state.deadline_set = payload.edit_deadline;

		},

		setEventUrl(state, payload) {
			state.eventInfo.EventUrl = payload;
		},

		changeVenue(state, payload) {
			state.eventInfo.VenueId = payload.venue_id;
			state.eventInfo.VenueName = payload.venue_name;
			if (payload.venue_name == "Central" || payload.venue_name == "All Venue") {
				state.eventInfo.venuetype = "Central";
			}
			else 
			{
				state.eventInfo.venuetype = "Local";
			}

		},
		changeVenuetype(state, payload) {
			state.eventInfo.venuetype = payload.venue_type;
		},

		// setEventName(state, payload) {
		// 	state.event_name = payload;

		// },
		// setVenueName(state, payload) {
		// 	state.venue_name = payload;

		// },
		setLogout(state) {
			state.eventInfo.EventId = "";
			state.eventInfo.VenueId = "";
			state.eventInfo.EventName = "";
			state.eventInfo.VenueName = "";
			state.eventInfo.CompanyNameSize = "";
			state.eventInfo.UType = "";
			state.eventInfo.UserName = "";
			state.eventInfo.venuetype = "";
			state.eventInfo.templateId = "";
			state.eventInfo.media_template_id = "";
			state.eventInfo.EventAdminID = "";
			state.eventInfo.Venues = "";
			state.token = "";
			sessionStorage.setItem("token", "");
			state.deadline_set = "";

		}
	},
	actions: {},
	modules: {}
});
