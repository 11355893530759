import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import excel from 'vue-excel-export'
import Viewer from "v-viewer";
import VueSimplePrintPlugin from 'vue-simple-print';
import axios from 'axios'

Vue.use(VueSimplePrintPlugin);
Vue.use(VueApexCharts)
Vue.use(excel)
Vue.use(Viewer)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
Vue.prototype.$apiPath='https://accred.api.dnanetworks.in/';
//Vue.prototype.$apiPath='https://localhost:44319/';
Vue.prototype.$aKey='ticketgenieapikey';

var axiosInstance = axios.create({
  baseURL: 'https://accred.api.dnanetworks.in',
 // baseURL: 'https://localhost:44319',
  
  headers: {    
    Authorization: 'Bearer ' + sessionStorage.getItem("token"),
    Appkey: 'ticketgenieapikey',
  }
})

Vue.prototype.$axios = axiosInstance

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
